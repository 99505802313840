.icon-logo_okna {
  fill: #fff;
  height: 34px;
}

@media (min-width: @screen-lg-min) {
  .icon-logo_okna {
    width: 166px;
  }
}

@media (max-width: @screen-md-max) {
  .icon-logo_okna {
    width: 133px;
  }
}

@media (max-width: @screen-sm-max) {
  .icon-logo_okna {
    width: 133px;
  }
}

@media (max-width: @screen-xs-max) {
  .icon-logo_okna {
    width: 140px;
  }
}

.icon-search {
  fill: #fff;
  height: 30px;
  width: 20px;
}
.icon-search:hover {
  fill: #f6cf45;
}

.footer .icon {
  fill: #fff;
}

.icon {
  display: inline-block;
}
.icon-news {
  width: 21px;
  height: 17px;
  fill: #464646;
}
.icon-logo_side {
  width: 41px;
  height: 26px;
  vertical-align: middle;
}
.icon-fb,
.icon-in {
  width: 20px;
  height: 19px;
}
.icon-eye {
  fill: #f6cf45;
  width: 30px;
  height: 25px;
}

.info__icon .icon {
  fill: #a3a3a3;
  vertical-align: middle;
}

.info__icon--margin50 {
  margin-right: 50px;
}
.icon-wallet {
  width: 30px;
  height: 26px;
}
.icon-buy_cart {
  width: 35px;
  height: 30px;
}
.icon-bricks {
  width: 38px;
  height: 26px;
}
.icon-plus_minus {
  width: 30px;
  height: 27px;
}
.icon-window {
  width: 19px;
  height: 32px;
}
.icon-warm_sun {
  width: 37px;
  height: 37px;
}
.icon-expand {
  width: 38px;
  height: 28px;
}
.icon-pig {
  width: 44px;
  height: 31px;
}
.icon-coins {
  width: 50px;
  height: 30px;
}
.icon-profile_temp {
  width: 30px;
  height: 30px;
}
.icon-profile_volume {
  width: 30px;
  height: 30px;
}
.icon-profile_sun {
  width: 30px;
  height: 30px;
}
.icon-otdelka_1steni,
.icon-otdelka_2pol,
.icon-otdelka_3sideing,
.icon-otdelka_4_utepl,
.icon-otdelka_5chernovoy {
  width: 128px;
  height: 128px;
}
.icon-plus {
  width: 33px;
  height: 33px;
}
.icon-metro {
  width: 30px;
  height: 30px;
}
.metro-green {
  fill: #006633;
}
.metro-blue {
  fill: #003399;
}
.metro-orange {
  fill: #ff9900;
}
.metro-yellow {
  fill: #ffcc00;
}
.icon-phone {
  fill: #fff;
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.icon-mail {
  width: 37px;
  height: 26px;
}
.icon-phone_ring {
  width: 26px;
  height: 27px;
}