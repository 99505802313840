.footer {
	display: block;
    position: absolute;
    width: 100%;
    min-width: 320px;
    @media (min-width: @screen-md-min) {
    	height: 437px;
    }
    @media (max-width: @screen-sm-max) {
    	height: 100px;
    }
    .icon {
    	fill: #fff;
    }
}

.footer__top {
	background: #3f4345;
	padding: 30px 10px;
	margin-bottom: -1px;
	font-size: 14px;
	line-height: 2;
    @media (min-width: @screen-lg-min) {
    	font-size: 16px;
    }
    @media (min-width: @screen-md-min) {
    	display: block;
    }
    @media (max-width: @screen-sm-max) {
    	display: none;
    }
    @media (max-width: @screen-xs-max) {
    	padding: 35px 0px;
    }
}

.footer__top__sm-border {
	height: 1px;
	width: 100%;
	background: #a9a9a9;
	margin-bottom: 27px;
}

.footer__top__item-wrapper {
	padding-bottom: 15px;
}

.footer__top__item__title {
	display: block;
	color: #fff;
	margin-bottom: 10px;
	text-decoration: none;
	&:hover, &:focus {
		color: #fff;
		text-decoration: underline;
	}
}

.footer__top__item__list {
	margin: 0;
    padding: 0;
    list-style: none;
    li {
    	display: block;
    	a {
			display: block;
			color: @darkgrey;
			text-decoration: none;
			&:hover, &:focus {
				color: @darkgrey;
				text-decoration: underline;
			}
		}
    }
}

.footer__top__to-top {
	text-align: center;
	text-decoration: underline;
	color: @darkgrey;
	&:hover, &:focus, &:active {
		text-decoration: none;
		color: @darkgrey;
	}
}

.footer__bottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	background: #393c3e;
	color: #fff;
	padding: 35px 25px 10px;
	width: 100%;
	@media (min-width: @screen-sm-min) {
		height: 101px;
	}
    @media (max-width: @screen-xs-max) {
    	padding: 35px 15px 10px;
    	height: 225px;
    }
	a {
		color: #fff;
		text-decoration: none;
		&:hover, &:focus {
			color: #fff;
			text-decoration: underline;
		}
	}
}

.footer__bottom__flex-item__first-level {
	display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: @screen-xs-max) {
    	width: 100%;
    }
}

.footer__bottom__flex-item__first-level-big {
	justify-content: space-between;
	flex-wrap: wrap;
	flex-grow: 1;
	@media (max-width: @screen-sm-max) {
    	flex-grow: 2;
    }
}

.footer__bottom__flex-item__first-level-small {
	justify-content: flex-end;
	flex-grow: 1;
}

.footer__bottom__flex-item__second-level {
	margin-bottom: 19px;
}

.footer__bottom__logo-wrapper, .footer__bottom__slogan {
	text-align: right;
	@media (max-width: @screen-xs-max) {
		width: 50%;
	}
}

.footer__bottom__copywright {
	@media (max-width: @screen-xs-max) {
		width: 100%;
		text-align: center;
	}
}