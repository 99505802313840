#night-modal {
	.modal-dialog {
		@media (min-width: @screen-sm-min) {
			width: 766px;
		}
		@media (max-width: @screen-xs-max) {
			width: 100%;
			margin: 0 10px;
		}
	}
	.close {
	    opacity: 1;
		span {
			color: #fff;
		}
	}
	.modal-content {
		color: #fff;
		background: url('../img/night_fon.jpg') center center no-repeat;
		background-size: cover;
	}
	.modal-title {
		margin-top: 20px;
		font-size: 34px;
		text-align: center;
	}
	.modal-text {
		margin-bottom: 35px;
		.text-n();
	}
	input {
		background: rgba(61, 73, 86, .72);
		border: 0;
	}
	.btn {
		background: @yellow;
		color: @black;
		padding: 12px 30px;
		@media (max-width: @screen-xs-max) {
			width: 100%;
		}
	}
	.modal-sign {
		opacity: .5;
		.text-n();
	}
}