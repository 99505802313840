//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);

// Colors

@black: #32373b;
@red: #e76f6f;
@pink: #ff5d81;
@lightpink: #f09485;
@orange: #fbb151;
@brightorange: #ff9536;
@yellow: #f6cf45;
@green: #75c58e;
@brightgreen: #63d084;
@linkblue: #456e9b;
@darkgrey: #a3a3a3;
@grey: #c3c3c3;
@lightgrey: #e1e1e1;
@yellow-grey: #c7c7ba;
@beige: #f5f4f0;
@icon-grey: #9b9b9b;
@icon-black: #4e5867;
@sand: #f3eedd;

.text-16 {
	font-size: 16px;
	line-height: 1.375;
}

.text-14 {
	font-size: 14px;
	line-height: 1.286;
}

.text-s {
	font-family: 'PT Sans', Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

.text-b {
	font-family: 'PT Sans', Helvetica, Arial, sans-serif;
	font-weight: bold;
}

.text-i {
	font-family: 'PT Sans', Helvetica, Arial, sans-serif;
	font-weight: normal;
	font-style: italic;
}

.text-n {
	font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
	font-weight: normal;
}

.text-nb {
	font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
	font-weight: bold;
}

.center-text {
	text-align: center;
}

.right-text {
	text-align: right;
}