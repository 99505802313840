.text-16 {
  font-size: 16px;
  line-height: 1.375;
}
.text-14 {
  font-size: 14px;
  line-height: 1.286;
}
.text-s {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
.text-b {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.text-i {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}
.text-n {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
.text-nb {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.center-text {
  text-align: center;
}
.right-text {
  text-align: right;
}
.header {
  height: 70px;
  background: #75c58e;
  width: 100%;
  padding: 0 25px;
  margin-top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: height 0.5s ease;
  align-items: flex-end;
  position: relative;
  z-index: 10;
}
@media (max-width: 767px) {
  .header {
    padding: 0 15px;
  }
}
.header-open {
  transition: height 0.5s ease;
}
@media (max-width: 991px) {
  .header-open {
    height: 140px;
  }
}
@media (max-width: 767px) {
  .header-open {
    height: 380px;
  }
}
.header__logo-wrapper {
  height: 70px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.icon-logo_okna {
  fill: #fff;
  height: 34px;
}
@media (min-width: 1200px) {
  .icon-logo_okna {
    width: 166px;
  }
}
@media (max-width: 1199px) {
  .icon-logo_okna {
    width: 133px;
  }
}
@media (max-width: 991px) {
  .icon-logo_okna {
    width: 133px;
  }
}
@media (max-width: 767px) {
  .icon-logo_okna {
    width: 140px;
  }
}
.header__nav {
  height: 70px;
}
@media (max-width: 991px) {
  .header__nav {
    position: absolute;
    top: -70px;
    left: 0;
    width: 100%;
    background: #3f4345;
    padding: 0 25px;
  }
}
@media (max-width: 767px) {
  .header__nav {
    top: -310px;
    height: 310px;
    padding: 30px 25px 30px 30px;
  }
}
.header__nav-open {
  top: 0;
  transition: all 0.5s ease;
}
.menu {
  padding: 0;
  margin: 0;
  height: 100%;
  list-style: none;
}
.menu__item {
  height: 100%;
  margin-right: 37px;
  display: inline-block;
  vertical-align: top;
}
.menu__item:last-child {
  margin-right: 0;
}
.menu__item:hover .menu__link {
  color: #f6cf45;
  text-decoration: none;
}
@media (min-width: 992px) {
  .menu__item:hover .menu__link {
    border-bottom: 5px solid #f6cf45;
  }
}
@media (max-width: 1199px) {
  .menu__item {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .menu__item {
    height: 25px;
    display: block;
    margin-bottom: 10px;
  }
  .menu__item:last-child {
    margin-bottom: 0;
  }
}
.menu__link {
  height: 100%;
  display: block;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  line-height: 70px;
  padding: 0;
  background-color: transparent;
}
.menu__link:hover,
.menu__link:focus,
.menu__link:active {
  color: #fff;
  text-decoration: none;
}
@media (max-width: 767px) {
  .menu__link {
    line-height: 20px;
  }
}
.submenu {
  display: none;
  background: #f4f4f4;
  padding: 25px;
  list-style: none;
  position: absolute;
  z-index: 10;
}
.submenu.fallback {
  display: none;
}
@media (max-width: 991px) {
  .submenu {
    display: none !important;
  }
}
.submenu__item {
  height: 25px;
  cursor: pointer;
  font-size: 16px;
}
.submenu__item:focus {
  outline: 0;
}
.submenu__link {
  color: #838383;
  text-decoration: none;
}
.submenu__link:hover,
.submenu__link:active,
.submenu__link:focus,
.submenu__link:active:focus {
  color: #000;
}
.header__r-wrapper {
  height: 70px;
  flex-grow: 2;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header__telephone {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}
.header__telephone--xs {
  background: #fff;
  display: none;
  width: 20px;
  height: 20px;
}
.header__man {
  height: 25px;
  width: 22px;
  display: inline-block;
  vertical-align: middle;
  background: url('../img/icons/lk_icon.svg') 50% bottom no-repeat;
  background-size: contain;
  position: relative;
}
.header__man:hover,
.header__man:focus,
.header__man:active {
  background: url('../img/icons/y_lk_icon.svg') 50% bottom no-repeat;
  background-size: contain;
}
.number-circle {
  min-height: 15px;
  min-width: 15px;
  padding: 2px;
  border-radius: 50%;
  background: #ff5d81;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  text-align: center;
}
.header__search {
  height: 30px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1199px) {
  .header__search {
    display: none;
  }
}
.icon-search {
  fill: #fff;
  height: 30px;
  width: 20px;
}
.icon-search:hover {
  fill: #f6cf45;
}
.header__search-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.header__search-icon-submit {
  fill: #9b9b9b;
}
.header__search-icon-submit:hover {
  fill: #9b9b9b;
}
.header__search-open {
  height: 30px;
  background: transparent;
}
.header__search-open:hover {
  background: transparent;
}
.header__search-open .header__search-icon {
  display: none;
}
.searchbox {
  height: 30px;
  width: 0;
  padding: 0 10px;
  float: right;
  position: relative;
  outline: 0;
  cursor: default;
  border-radius: 15px;
  background: #fff;
  display: none;
}
.searchbox__input {
  height: 28px;
  width: 150px;
  padding: 0;
  margin-top: 1px;
  float: left;
  outline: 0;
  border: 0;
}
.searchbox__submit {
  display: inline-block;
  height: 30px;
  width: 18px;
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
}
.burger {
  width: 21px;
  height: 19px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}
.burger span {
  display: block;
  width: 100%;
  height: 3px;
  background: #fff;
}
.burger span:not(:last-child) {
  margin-bottom: 5px;
}
.burger:hover span,
.burger:active span,
.burger:focus span {
  background: #f6cf45;
}
.header__burger {
  margin-top: 7px;
  margin-left: 30px;
}
@media (min-width: 992px) {
  .header__burger {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .header__burger {
    display: inline-block !important;
    margin-top: 0;
  }
}
.burger {
  width: 21px;
  height: 19px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}
.burger span {
  display: block;
  width: 100%;
  height: 3px;
  background: #fff;
}
.burger span:not(:last-child) {
  margin-bottom: 5px;
}
.burger:hover span,
.burger:active span,
.burger:focus span {
  background: #f6cf45;
}
.header__burger {
  margin-top: 7px;
  margin-left: 30px;
}
@media (min-width: 992px) {
  .header__burger {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .header__burger {
    display: inline-block !important;
    margin-top: 0;
  }
}
.footer {
  display: block;
  position: absolute;
  width: 100%;
  min-width: 320px;
}
@media (min-width: 992px) {
  .footer {
    height: 437px;
  }
}
@media (max-width: 991px) {
  .footer {
    height: 100px;
  }
}
.footer .icon {
  fill: #fff;
}
.footer__top {
  background: #3f4345;
  padding: 30px 10px;
  margin-bottom: -1px;
  font-size: 14px;
  line-height: 2;
}
@media (min-width: 1200px) {
  .footer__top {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .footer__top {
    display: block;
  }
}
@media (max-width: 991px) {
  .footer__top {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer__top {
    padding: 35px 0px;
  }
}
.footer__top__sm-border {
  height: 1px;
  width: 100%;
  background: #a9a9a9;
  margin-bottom: 27px;
}
.footer__top__item-wrapper {
  padding-bottom: 15px;
}
.footer__top__item__title {
  display: block;
  color: #fff;
  margin-bottom: 10px;
  text-decoration: none;
}
.footer__top__item__title:hover,
.footer__top__item__title:focus {
  color: #fff;
  text-decoration: underline;
}
.footer__top__item__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.footer__top__item__list li {
  display: block;
}
.footer__top__item__list li a {
  display: block;
  color: #a3a3a3;
  text-decoration: none;
}
.footer__top__item__list li a:hover,
.footer__top__item__list li a:focus {
  color: #a3a3a3;
  text-decoration: underline;
}
.footer__top__to-top {
  text-align: center;
  text-decoration: underline;
  color: #a3a3a3;
}
.footer__top__to-top:hover,
.footer__top__to-top:focus,
.footer__top__to-top:active {
  text-decoration: none;
  color: #a3a3a3;
}
.footer__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #393c3e;
  color: #fff;
  padding: 35px 25px 10px;
  width: 100%;
}
@media (min-width: 768px) {
  .footer__bottom {
    height: 101px;
  }
}
@media (max-width: 767px) {
  .footer__bottom {
    padding: 35px 15px 10px;
    height: 225px;
  }
}
.footer__bottom a {
  color: #fff;
  text-decoration: none;
}
.footer__bottom a:hover,
.footer__bottom a:focus {
  color: #fff;
  text-decoration: underline;
}
.footer__bottom__flex-item__first-level {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .footer__bottom__flex-item__first-level {
    width: 100%;
  }
}
.footer__bottom__flex-item__first-level-big {
  justify-content: space-between;
  flex-wrap: wrap;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .footer__bottom__flex-item__first-level-big {
    flex-grow: 2;
  }
}
.footer__bottom__flex-item__first-level-small {
  justify-content: flex-end;
  flex-grow: 1;
}
.footer__bottom__flex-item__second-level {
  margin-bottom: 19px;
}
.footer__bottom__logo-wrapper,
.footer__bottom__slogan {
  text-align: right;
}
@media (max-width: 767px) {
  .footer__bottom__logo-wrapper,
  .footer__bottom__slogan {
    width: 50%;
  }
}
@media (max-width: 767px) {
  .footer__bottom__copywright {
    width: 100%;
    text-align: center;
  }
}
.icon-logo_okna {
  fill: #fff;
  height: 34px;
}
@media (min-width: 1200px) {
  .icon-logo_okna {
    width: 166px;
  }
}
@media (max-width: 1199px) {
  .icon-logo_okna {
    width: 133px;
  }
}
@media (max-width: 991px) {
  .icon-logo_okna {
    width: 133px;
  }
}
@media (max-width: 767px) {
  .icon-logo_okna {
    width: 140px;
  }
}
.icon-search {
  fill: #fff;
  height: 30px;
  width: 20px;
}
.icon-search:hover {
  fill: #f6cf45;
}
.footer .icon {
  fill: #fff;
}
.icon {
  display: inline-block;
}
.icon-news {
  width: 21px;
  height: 17px;
  fill: #464646;
}
.icon-logo_side {
  width: 41px;
  height: 26px;
  vertical-align: middle;
}
.icon-fb,
.icon-in {
  width: 20px;
  height: 19px;
}
.icon-eye {
  fill: #f6cf45;
  width: 30px;
  height: 25px;
}
.info__icon .icon {
  fill: #a3a3a3;
  vertical-align: middle;
}
.info__icon--margin50 {
  margin-right: 50px;
}
.icon-wallet {
  width: 30px;
  height: 26px;
}
.icon-buy_cart {
  width: 35px;
  height: 30px;
}
.icon-bricks {
  width: 38px;
  height: 26px;
}
.icon-plus_minus {
  width: 30px;
  height: 27px;
}
.icon-window {
  width: 19px;
  height: 32px;
}
.icon-warm_sun {
  width: 37px;
  height: 37px;
}
.icon-expand {
  width: 38px;
  height: 28px;
}
.icon-pig {
  width: 44px;
  height: 31px;
}
.icon-coins {
  width: 50px;
  height: 30px;
}
.icon-profile_temp {
  width: 30px;
  height: 30px;
}
.icon-profile_volume {
  width: 30px;
  height: 30px;
}
.icon-profile_sun {
  width: 30px;
  height: 30px;
}
.icon-otdelka_1steni,
.icon-otdelka_2pol,
.icon-otdelka_3sideing,
.icon-otdelka_4_utepl,
.icon-otdelka_5chernovoy {
  width: 128px;
  height: 128px;
}
.icon-plus {
  width: 33px;
  height: 33px;
}
.icon-metro {
  width: 30px;
  height: 30px;
}
.metro-green {
  fill: #006633;
}
.metro-blue {
  fill: #003399;
}
.metro-orange {
  fill: #ff9900;
}
.metro-yellow {
  fill: #ffcc00;
}
.icon-phone {
  fill: #fff;
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.icon-mail {
  width: 37px;
  height: 26px;
}
.icon-phone_ring {
  width: 26px;
  height: 27px;
}
@media (min-width: 768px) {
  #night-modal .modal-dialog {
    width: 766px;
  }
}
@media (max-width: 767px) {
  #night-modal .modal-dialog {
    width: 100%;
    margin: 0 10px;
  }
}
#night-modal .close {
  opacity: 1;
}
#night-modal .close span {
  color: #fff;
}
#night-modal .modal-content {
  color: #fff;
  background: url('../img/night_fon.jpg') center center no-repeat;
  background-size: cover;
}
#night-modal .modal-title {
  margin-top: 20px;
  font-size: 34px;
  text-align: center;
}
#night-modal .modal-text {
  margin-bottom: 35px;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
#night-modal input {
  background: rgba(61, 73, 86, 0.72);
  border: 0;
}
#night-modal .btn {
  background: #f6cf45;
  color: #32373b;
  padding: 12px 30px;
}
@media (max-width: 767px) {
  #night-modal .btn {
    width: 100%;
  }
}
#night-modal .modal-sign {
  opacity: .5;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
}
html,
body {
  background: #fff;
  width: 100%;
  min-width: 320px;
  height: 100%;
  min-height: 100% !important;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  color: #32373b;
}
a {
  color: #456e9b;
  text-decoration: underline;
}
a:hover,
a:active,
a:focus {
  color: #456e9b;
  text-decoration: none;
  outline: none;
}
a.yellow {
  color: #f6cf45;
}
a.yellow:hover,
a.yellow:active,
a.yellow:focus {
  color: #f6cf45;
}
h1 {
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin: 45px 0 15px;
  color: #75c58e;
}
@media (max-width: 767px) {
  h1 {
    font-size: 36px;
  }
}
@media (min-width: 768px) {
  h1 {
    font-size: 38px;
  }
}
@media (min-width: 992px) {
  h1 {
    font-size: 40px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 42px;
  }
}
h2 {
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin: 0 0 30px 0;
  color: #75c58e;
}
@media (max-width: 767px) {
  h2 {
    font-size: 26px;
  }
}
@media (min-width: 768px) {
  h2 {
    font-size: 28px;
  }
}
@media (min-width: 992px) {
  h2 {
    font-size: 30px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 32px;
  }
}
h3 {
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin: 0 0 25px 0;
  color: #75c58e;
}
@media (max-width: 767px) {
  h3 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  h3 {
    font-size: 22px;
  }
}
@media (min-width: 992px) {
  h3 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  h3 {
    font-size: 26px;
  }
}
h3.tech:before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 25px;
  background: url('../img/icons/teplo_instruments.svg') center center no-repeat;
  margin-right: 15px;
  vertical-align: bottom;
}
h4 {
  font-size: 22px;
  line-height: 1;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin: 0;
}
h5 {
  font-size: 16px;
  line-height: 1.25;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin: 0;
}
i,
em {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}
b,
strong {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
p {
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 35px;
}
p i,
p em {
  color: #a3a3a3;
}
@media (max-width: 767px) {
  p {
    font-size: 14px;
    line-height: 1.286;
  }
}
input {
  height: 50px;
}
textarea {
  padding-top: 15px;
  resize: none;
}
input,
textarea {
  border: 1px solid #e1e1e1;
  padding-left: 15px;
  padding-right: 15px;
}
input:active,
textarea:active,
input:focus,
textarea:focus,
input:active:focus,
textarea:active:focus {
  outline: none;
}
input::-ms-clear {
  display: none;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
}
blockquote {
  border-color: #75c58e;
  border-width: 10px;
  padding: 0 35px;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  color: #c3c3c3;
}
@media (max-width: 767px) {
  blockquote {
    font-size: 24px;
  }
}
@media (min-width: 768px) {
  blockquote {
    font-size: 36px;
  }
}
@media (min-width: 992px) {
  blockquote {
    font-size: 42px;
  }
}
.large-text {
  font-size: 26px;
}
.nb {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.black {
  color: #32373b;
}
.yellow {
  color: #f6cf45;
}
.orange {
  color: #fbb151;
}
.white {
  color: #fff;
}
.lightpink {
  color: #f09485;
}
.icon {
  display: inline-block;
}
.icon-news {
  width: 21px;
  height: 17px;
  fill: #464646;
}
.icon-logo_side {
  width: 41px;
  height: 26px;
  vertical-align: middle;
}
.icon-fb,
.icon-in {
  width: 20px;
  height: 19px;
}
.btn-brush-icon {
  width: 23px;
  height: 23px;
  vertical-align: middle;
  fill: #fff;
  margin-right: 10px;
}
.bordered {
  border: 1px solid #c3c3c3;
  padding: 25px 30px;
}
@media (max-width: 767px) {
  .bordered {
    padding: 25px 15px;
  }
}
.container-fluid {
  padding: 0 30px;
}
@media (max-width: 767px) {
  .container-fluid {
    padding: 0 15px;
  }
}
.page-wrapper {
  min-height: 100%;
  height: auto !important;
}
@media (min-width: 1200px) {
  .page-wrapper {
    margin: 0 auto -537px;
    padding-bottom: 537px;
  }
}
@media (min-width: 992px) {
  .page-wrapper {
    margin: 0 auto -437px;
    padding-bottom: 437px;
  }
}
@media (max-width: 991px) {
  .page-wrapper {
    margin: 0 auto -100px;
    padding-bottom: 100px;
  }
}
.content {
  padding: 0 0 30px;
  margin-left: auto;
  margin-right: auto;
}
.content .container-fluid {
  max-width: 1200px;
}
.content--main {
  padding: 0;
}
.content--main .container-fluid {
  max-width: 1600px;
}
.btn {
  padding: 18px 30px;
  font-size: 16px;
  border-width: 2px;
  border-style: solid;
  border-radius: 0;
  text-decoration: none;
  color: #32373b;
  background: transparent;
  outline: 0;
  transition: background ease .5s;
}
.btn:active,
.btn:focus,
.btn:active:focus {
  outline: none;
  box-shadow: none;
}
@media (max-width: 767px) {
  .btn {
    font-size: 14px;
    padding: 12px 30px;
  }
}
.btn--yellow {
  border-color: #f6cf45;
}
.btn--yellow:hover {
  background: #f6cf45;
}
.btn--yellow.btn--fill {
  background: #f6cf45;
}
.btn--orange {
  border-color: #fbb151;
}
.btn--orange:hover {
  background: #fbb151;
}
.btn--orange.btn--fill {
  background: #fbb151;
}
.btn--brightorange {
  border-color: #ff9536;
}
.btn--brightorange:hover {
  background: #ff9536;
}
.btn--brightorange.btn--fill {
  color: #fff;
  background: #ff9536;
}
.btn--green {
  border-color: #75c58e;
}
.btn--green:hover {
  background: #75c58e;
}
.btn--green.btn--fill {
  background: #75c58e;
}
.btn--lightpink {
  border-color: #f09485;
}
.btn--lightpink:hover {
  background: #f09485;
}
.btn--lightpink.btn--fill {
  color: #fff;
  background: #f09485;
}
.btn--dotted {
  border-color: #fff;
  border-style: dashed;
}
@media (max-width: 767px) {
  .btn--fw {
    width: 100%;
  }
}
.btn--brush span {
  vertical-align: middle;
}
.line-form {
  background-color: #f3eedd;
  padding: 30px 65px;
  font-size: 0;
}
@media (max-width: 767px) {
  .line-form {
    padding: 30px 15px;
  }
}
.line-form__text {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 25px;
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 1199px) {
  .line-form__text {
    width: 100%;
    display: block;
    margin-bottom: 15px;
  }
}
.line-form__form {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 1199px) {
  .line-form__form {
    width: 100%;
    display: block;
  }
}
.line-form__input,
.line-form__button {
  display: inline-block;
  vertical-align: middle;
  height: 55px;
}
.line-form__input {
  border: 0;
  width: 209px;
  margin-right: 25px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .line-form__input {
    margin-bottom: 15px;
    width: 100%;
  }
}
.line-form__button {
  padding: 15px 20px;
}
.mini-nav {
  text-align: center;
}
.mini-nav__text,
.mini-nav__icons {
  display: inline-block;
  vertical-align: middle;
  color: #a3a3a3;
}
.mini-nav__text {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 22px;
  margin-right: 25px;
}
@media (max-width: 767px) {
  .mini-nav__text {
    margin-right: 0;
    width: 100%;
  }
}
.mini-nav__icons {
  font-size: 40px;
  line-height: 1;
}
.mini-nav__icons:hover {
  color: #f6cf45;
}
@media (max-width: 767px) {
  .mini-nav__icons {
    margin-right: 0;
    width: 100%;
  }
}
.mini-nav__icon {
  margin-right: 10px;
}
.price {
  display: inline-block;
  padding: 0 5px;
  background: #f6cf45;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 20px;
}
.price:after {
  content: '₽';
}
.nav-panel {
  background: #f5f4f0;
  text-align: center;
}
.nav-panel__item__wrapper {
  text-align: center;
  padding: 15px 0;
}
.nav-panel__item {
  display: inline-block;
  color: #a3a3a3;
  text-decoration: none;
  font-size: 46px;
  line-height: 46px;
  height: 46px;
}
.nav-panel__item:not(:last-child) {
  margin-right: 15px;
}
.nav-panel__item:hover {
  color: #f6cf45;
}
.page-heading {
  text-align: center;
  margin-bottom: 70px;
}
.para-block-wrapper {
  height: 386px;
  overflow: hidden;
  position: relative;
}
.para-block {
  height: 100%;
  width: 100%;
  background-size: auto 200%;
  background-position-y: 0px;
  background-position-x: 50%;
  background-repeat: no-repeat;
  transform: translateZ(0);
}
.para-block__btn-wrapper {
  position: absolute;
  width: 100%;
  bottom: 40px;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .para-block__btn-wrapper {
    bottom: 20px;
  }
}
.adv {
  width: 100%;
  height: 695px;
  padding: 55px 0 35px;
  position: relative;
  text-align: center;
  color: #fff;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.adv__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.adv__nav {
  position: relative;
  z-index: 5;
}
.adv__nav__item {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  font-size: 40px;
  line-height: 1;
}
.adv__nav__item:not(:last-child) {
  margin-right: 10px;
}
.adv__nav__item:hover,
.adv__nav__item:active,
.adv__nav__item:focus,
.adv__nav__item:hover:active,
.adv__nav__item:hover:focus,
.adv__nav__item:active:focus {
  color: #f6cf45;
  text-decoration: none;
}
.adv__nav__item.active {
  color: #f6cf45;
}
.adv__content {
  position: relative;
  padding: 50px 70px;
  width: 80%;
  max-width: 720px;
  text-align: center;
  z-index: 5;
  margin: auto;
}
.adv__content a {
  color: #f6cf45;
}
@media (max-width: 767px) {
  .adv__content {
    max-width: 100%;
    padding: 50px 10px;
  }
}
.adv__content__icon {
  color: #f6cf45;
  margin: 0 auto 35px;
  font-family: 'oknavips-regular';
  font-size: 56px;
  line-height: 1;
}
.adv__content__icon--balkon:before {
  content: '\1017';
}
.adv__content__icon--teplopaket:before {
  content: '\1009';
}
.adv__content__icon--steklopaket:before {
  content: '\1008';
}
.adv__content__big-text {
  font-size: 32px;
  line-height: 1.4;
  color: #fff;
  margin-bottom: 25px;
}
.adv__content__small-text {
  font-size: 16px;
  line-height: 1.375;
  color: #fff;
  margin-bottom: 25px;
}
.adv__price-wrapper {
  display: inline-block;
  font-size: 28px;
  margin-bottom: 25px;
}
.adv__eye {
  z-index: 5;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}
.icon-eye {
  fill: #f6cf45;
  width: 30px;
  height: 25px;
}
.useful {
  height: 250px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 767px) {
  .useful {
    margin-bottom: 15px;
  }
}
.useful__overlay {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  padding: 15px;
}
.useful__content {
  margin: auto;
  text-align: center;
}
.useful__title {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 27px;
  color: #f6cf45;
  margin-bottom: 10px;
}
.useful__text {
  color: #fff;
}
.next-block {
  height: 250px;
  background-color: #f6cf45;
  padding: 25px;
  display: flex;
}
.next-block__content {
  margin: auto;
  text-align: center;
}
.next-block__word {
  font-size: 18px;
}
.next-block__title {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 33px;
  line-height: 1;
}
.info {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
  width: 100%;
}
@media (max-width: 767px) {
  .info--v {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .info--v .info__item {
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .info--v .info__text {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .info--v .info__icon {
    margin-bottom: 15px;
  }
}
.info__item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 35px;
  margin-bottom: 10px;
}
.info__item--top {
  align-items: flex-start;
}
.info__icon {
  margin-right: 25px;
  flex-shrink: 0;
}
.info__icon .icon {
  fill: #a3a3a3;
  vertical-align: middle;
}
.info__icon--margin50 {
  margin-right: 50px;
}
.icon-wallet {
  width: 30px;
  height: 26px;
}
.icon-buy_cart {
  width: 35px;
  height: 30px;
}
.icon-bricks {
  width: 38px;
  height: 26px;
}
.icon-plus_minus {
  width: 30px;
  height: 27px;
}
.icon-window {
  width: 19px;
  height: 32px;
}
.icon-warm_sun {
  width: 37px;
  height: 37px;
}
.icon-expand {
  width: 38px;
  height: 28px;
}
.icon-pig {
  width: 44px;
  height: 31px;
}
.icon-coins {
  width: 50px;
  height: 30px;
}
.icon-profile_temp {
  width: 30px;
  height: 30px;
}
.icon-profile_volume {
  width: 30px;
  height: 30px;
}
.icon-profile_sun {
  width: 30px;
  height: 30px;
}
.icon-otdelka_1steni,
.icon-otdelka_2pol,
.icon-otdelka_3sideing,
.icon-otdelka_4_utepl,
.icon-otdelka_5chernovoy {
  width: 128px;
  height: 128px;
}
.icon-plus {
  width: 33px;
  height: 33px;
}
.icon-metro {
  width: 30px;
  height: 30px;
}
.metro-green {
  fill: #006633;
}
.metro-blue {
  fill: #003399;
}
.metro-orange {
  fill: #ff9900;
}
.metro-yellow {
  fill: #ffcc00;
}
.icon-phone {
  fill: #fff;
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.icon-mail {
  width: 37px;
  height: 26px;
}
.icon-phone_ring {
  width: 26px;
  height: 27px;
}
.info__text--grey {
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #a3a3a3;
}
.info__text--grey a {
  color: #a3a3a3;
  text-decoration: none;
}
.info__text--grey a:hover,
.info__text--grey a:active,
.info__text--grey a:focus {
  color: #a3a3a3;
}
.info__text--green {
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 1.09;
  color: #75c58e;
}
.info__text--green a {
  display: block;
  font-size: 14px;
  line-height: 1.286;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  margin-top: 5px;
}
.gradient-right {
  height: 100%;
  width: 70px;
  position: absolute;
  top: 0;
  right: -1px;
  background: -webkit-linear-gradient(to left, #fff, transparent);
  background: -moz-linear-gradient(to left, #fff, transparent);
  background: -ms-linear-gradient(to left, #fff, transparent);
  background: -o-linear-gradient(to left, #fff, transparent);
  background: linear-gradient(to left, #fff, transparent);
}
.row--flex {
  display: flex;
}
.row--content-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 767px) {
  .block--big-margin {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) {
  .block--big-margin {
    margin-bottom: 85px;
  }
}
.block__item {
  margin-bottom: 25px;
}
.block__item--big-margin {
  margin-bottom: 40px;
}
.img-left {
  float: left;
}
@media (max-width: 767px) {
  .img-left {
    float: none;
    margin: 0 auto;
  }
}
.img-right {
  float: right;
}
@media (max-width: 767px) {
  .img-right {
    float: none;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .img-right--sm-center {
    float: none;
    margin: 0 auto;
  }
}
.section__header {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  margin-bottom: 40px;
}
.news-preview {
  margin-bottom: 40px;
  position: relative;
  display: block;
}
.news-preview--main {
  margin-bottom: 75px;
  font-size: 16px;
  line-height: 1.375;
}
@media (max-width: 991px) {
  .news-preview--main {
    font-size: 14px;
    line-height: 1.286;
  }
}
@media (max-width: 767px) {
  .news-preview--main {
    margin-bottom: 40px;
  }
}
.news-preview--main .news-preview__date {
  margin-bottom: 30px;
}
.news-preview .sticker {
  top: -5px;
  left: 80px;
}
.news-preview__date {
  color: #a3a3a3;
  margin-bottom: 15px;
  display: inline-block;
}
.news-preview__text {
  display: block;
}
.news-preview__img {
  background: url('http://placekitten.com/276/187') center center no-repeat;
  background-size: cover;
  height: 155px;
  margin-bottom: 20px;
  overflow: hidden;
}
.sticker {
  display: inline-block;
  padding: 5px 10px;
  position: absolute;
  border-radius: 11px;
}
.sticker--important {
  background: #e76f6f;
  color: #fff;
}
.sticker--discount {
  background: #63d084;
  color: #fff;
}
.img-pod {
  margin: 0 auto 30px;
}
.info-v {
  width: 85%;
  margin: 0 auto;
}
.info-v h3 {
  text-align: center;
}
.info-v p {
  text-align: center;
}
@media (max-width: 767px) {
  .info-v p {
    text-align: left;
  }
}
.review__wrapper-out {
  display: none;
}
.review__wrapper {
  column-width: 210px;
  column-gap: 30px;
  position: relative;
}
.review__overlay {
  position: absolute;
  height: 75px;
  width: 100%;
  bottom: 0;
  background: -ms-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0) 100%);
}
.review {
  border: 1px solid #e1e1e1;
  padding: 30px;
  margin-bottom: 30px;
  display: inline-block;
  break-inside: avoid-column;
  font-size: 14px;
}
.review__header {
  color: #75c58e;
  margin-bottom: 15px;
}
.review p {
  font-size: 14px;
  margin-bottom: 20px;
}
.review__sign {
  color: #969696;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}
.modal-content {
  border-radius: 0;
}
.modal-header {
  border-bottom: 0;
  padding: 32px 25px 15px;
}
.close {
  outline: 0;
}
.close span {
  font-size: 40px;
  font-weight: 100;
  line-height: 0.5;
}
.modal-header-img {
  width: 88px;
  height: 106px;
  background-image: url('../img/main/buttons_sprite.png');
  margin: 37px auto 20px;
}
.modal-header-img--measure {
  background-position: 0 0;
}
.modal-header-img--question {
  background-position: -176px 0;
}
.modal-header-img--callback {
  background-position: -265px 0;
}
.modal-title {
  text-align: center;
  font-size: 22px;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.modal-body {
  padding: 35px 95px 85px;
}
@media (max-width: 767px) {
  .modal-body {
    padding: 35px 30px 85px;
  }
}
.modal-content input,
.modal-content textarea {
  width: 100%;
  margin-bottom: 20px;
}
.modal-content textarea {
  height: 157px;
}
/*------------DIVIDE PAGE START--------------*/
.tile__container {
  overflow: hidden;
}
.tile {
  display: block;
  float: left;
  position: relative;
}
@media (max-width: 767px) {
  .tile {
    padding-bottom: 85%;
  }
}
@media (min-width: 768px) {
  .tile {
    padding-bottom: 50%;
  }
}
@media (min-width: 992px) {
  .tile {
    padding-bottom: 33.33333333%;
  }
}
.tile__inner {
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  color: #32373b;
  background: #f5f4f0;
}
.tile__inner:hover,
.tile__inner:active,
.tile__inner:focus,
.tile__inner:hover:active,
.tile__inner:active:focus {
  text-decoration: none;
  color: #32373b;
}
.tile__inner--bordered {
  background: transparent;
  padding: 8% 30px 35px;
  border: 4px solid #f6cf45;
  font-family: 'PT Sans Narrow', Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 49px;
  line-height: 1;
  color: #c7c7ba;
}
.tile__inner--bordered:hover,
.tile__inner--bordered:active,
.tile__inner--bordered:focus,
.tile__inner--bordered:hover:active,
.tile__inner--bordered:active:focus {
  color: #c7c7ba;
}
.tile__inner.disable {
  filter: grayscale(100%);
  pointer-events: none;
  cursor: default;
}
.tile__inner h3 {
  margin-bottom: 15px;
}
.tile__img {
  width: 100%;
}
.tile__text {
  position: absolute;
  left: 35px;
  right: 35px;
  bottom: 0;
  text-align: left;
}
/*------------DIVIDE PAGE END--------------*/
/*-------------FILTER START--------------*/
.tab__content {
  display: none;
}
.tab__content:first-child {
  display: block;
}
.filter__link-wrapper {
  margin: 40px 0;
}
.filter__link {
  font-size: 16px;
  line-height: 1.375;
  color: #a3a3a3;
  text-decoration: none;
}
.filter__link:not(:last-child):after {
  content: ' / ';
  color: #a3a3a3;
}
.filter__link.active span {
  color: #a3a3a3;
  text-decoration: none;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
}
.filter__link.active span:hover,
.filter__link.active span:focus {
  cursor: default;
}
.filter__link span {
  color: #456e9b;
  text-decoration: underline;
  white-space: nowrap;
}
.filter__link span:hover,
.filter__link span:focus {
  text-decoration: none;
  cursor: pointer;
}
.filter__btn-wrapper {
  text-align: justify;
  padding: 2px;
}
.filter__btn-wrapper:after {
  content: ' i i i i i i';
  word-spacing: 65px;
  padding-left: 62px;
  visibility: hidden;
}
@media (max-width: 767px) {
  .filter__btn-wrapper {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  .filter__btn-wrapper {
    margin-bottom: -20px;
  }
}
.filter__btn {
  width: 68px;
  height: 47px;
  margin: 0 0 25px 0;
  padding: 3px;
  display: inline-block;
}
.filter__btn a {
  display: block;
  width: 100%;
  height: 100%;
}
.filter__btn--default a {
  background: url('../img/icons/btn-default.png') center center no-repeat;
}
.filter__btn.active {
  outline: 2px solid #75c58e;
  cursor: default;
}
.filter__sign {
  font-size: 16px;
  line-height: 1.375;
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: italic;
  color: #a3a3a3;
}
.filter__sign--normal {
  font-family: 'PT Sans', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}
/*-------------FILTER END--------------*/
/*-------------SELECT START--------------*/
.select2-container--default .select2-selection--single {
  border-radius: 0;
  border: solid 1px #d2d2d2;
  height: 44px;
}
.select2-container--default .select2-selection--single:focus {
  outline: 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 42px;
}
.select2-dropdown {
  border-radius: 0;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #d2d2d2;
  border-radius: 0;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 44px;
}
/*-------------SELECT END--------------*/
/*-------------COME BANNER START--------------*/
.come {
  margin: 30px 0 40px;
  padding: 25px 0 20px;
  background-color: #f5f4f0;
}
.come h3 {
  margin-bottom: 0;
}
.come__inner {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
}
@media (min-width: 1200px) {
  .come__inner {
    justify-content: center;
  }
}
.come__icon,
.come__content,
.come__form {
  padding: 10px 0;
}
.come__icon {
  padding-right: 20px;
}
.come__content {
  padding-right: 50px;
}
.come__form input {
  border: 0;
  margin-right: 26px;
}
.come__form input,
.come__form .btn {
  vertical-align: top;
}
@media (max-width: 767px) {
  .come__form input,
  .come__form .btn {
    width: 100%;
    margin-bottom: 15px;
  }
}
.come__form .btn {
  margin-bottom: 0;
  padding: 12px 30px;
}
@media (max-width: 767px) {
  .come__form {
    width: 100%;
  }
}
/*-------------COME BANNER END--------------*/
/*-------------SLIDER LAST WORKS START--------------*/
.slide-container--last-works {
  position: relative;
}
.slide-container--last-works .slider__item {
  margin-right: 30px;
  height: 205px;
  width: 265px;
  background: #e1e1e1;
  position: relative;
}
.slide-container--last-works .slider__item:active,
.slide-container--last-works .slider__item:focus,
.slide-container--last-works .slider__item:active:focus {
  outline: none;
}
.slide-container--last-works .slick-arrow {
  border: 10px solid transparent;
  z-index: 10;
}
.slide-container--last-works .slick-arrow:before,
.slide-container--last-works .slick-arrow:after {
  content: '';
}
.slide-container--last-works .slick-prev {
  border-right: 6px solid #32373b;
  left: 25px;
}
.slide-container--last-works .slick-next {
  border-left: 6px solid #32373b;
  right: 25px;
}
.slider--last-works__overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(246, 207, 69, 0.7);
}
.slider--last-works__content {
  position: absolute;
  padding: 30px;
  bottom: 0;
}
.slider--last-works__content-xs {
  margin-bottom: 20px;
  display: none;
}
.slider--last-works__price {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}
/*-------------SLIDER LAST WORKS END--------------*/
