@import 'include/variables.less';
@import 'include/header.less';
@import 'include/footer.less';
@import 'include/icons.less';
@import 'include/night-banner.less';

html, body {
	background: #fff;
	width: 100%;
	min-width: 320px;
	height: 100%;
    min-height: 100% !important;
    margin: 0;
    padding: 0;
    position: relative;
    font-family: 'PT Sans', Helvetica, Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    color: @black;
}

a {
	color: @linkblue;
	text-decoration: underline;
	&:hover, &:active, &:focus {
		color: @linkblue;
		text-decoration: none;
		outline: none;
	}
	&.yellow {
		color: @yellow;
		&:hover, &:active, &:focus {
			color: @yellow;
		}

	}
}

h1 {
	line-height: 1;
	.text-n;
	margin: 45px 0 15px;
	color: @green;
	@media (max-width: @screen-xs-max) {
		font-size: 36px;
	}
	@media (min-width: @screen-sm-min) {
		font-size: 38px;
	}
	@media (min-width: @screen-md-min) {
		font-size: 40px;
	}
	@media (min-width: @screen-lg-min) {
		font-size: 42px;
	}
}

h2 {
	line-height: 1;
	.text-n;
	margin: 0 0 30px 0;
	color: @green;
	@media (max-width: @screen-xs-max) {
		font-size: 26px;
	}
	@media (min-width: @screen-sm-min) {
		font-size: 28px;
	}
	@media (min-width: @screen-md-min) {
		font-size: 30px;
	}
	@media (min-width: @screen-lg-min) {
		font-size: 32px;
	}
}

h3 {
	line-height: 1;
	.text-nb;
	margin: 0 0 25px 0;
	color: @green;
	@media (max-width: @screen-xs-max) {
		font-size: 20px;
	}
	@media (min-width: @screen-sm-min) {
		font-size: 22px;
	}
	@media (min-width: @screen-md-min) {
		font-size: 24px;
	}
	@media (min-width: @screen-lg-min) {
		font-size: 26px;
	}
	&.tech {
		&:before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 25px;
			background: url('../img/icons/teplo_instruments.svg') center center no-repeat;
			margin-right: 15px;
			vertical-align: bottom;
		}
	}
}

h4 {
	font-size: 22px;
	line-height: 1;
	.text-nb;
	margin: 0;
}

h5 {
	font-size: 16px;
	line-height: 1.25;
	.text-nb;
	margin: 0;
}

i, em {
	.text-i;
}

b, strong {
	.text-b;
}

p {
	.text-16;
	.text-s;
	margin-bottom: 35px;
	i, em {
		color: @darkgrey;
	}
	@media (max-width: @screen-xs-max) {
		.text-14();
	}
}

input {
	height: 50px;
}

textarea {
	padding-top: 15px;
	resize: none;
}

input, textarea {
	border: 1px solid @lightgrey;
	padding-left: 15px;
	padding-right: 15px;
	&:active, &:focus, &:active:focus {
		outline: none;
	}
}

input::-ms-clear {
	display: none;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
	-webkit-box-shadow: inset 0 0 0 50px #fff !important;
}

blockquote {
	border-color: @green;
	border-width: 10px;
	padding: 0 35px;
	.text-s;
	line-height: 1;
	color: @grey;
	@media (max-width: @screen-xs-max) {
		font-size: 24px;
	}
	@media (min-width: @screen-sm-min) {
		font-size: 36px;
	}
	@media (min-width: @screen-md-min) {
		font-size: 42px;
	}
}

.large-text {
	font-size: 26px;
}

.nb {
	.text-nb();
}

.black {
	color: @black;
}

.yellow {
	color: @yellow;
}

.orange {
	color: @orange;
}

.white {
	color: #fff;
}

.lightpink {
	color: @lightpink;
}

.icon {
    display: inline-block;
}

.icon-news {
	width: 21px;
	height: 17px;
	fill: #464646;
}

.icon-logo_side {
	width: 41px;
	height: 26px;
    vertical-align: middle;
}

.icon-fb, .icon-in {
	width: 20px;
	height: 19px;
}

.btn-brush-icon {
	width: 23px;
	height: 23px;
	vertical-align: middle;
	fill: #fff;
	margin-right: 10px;
}

.bordered {
	border: 1px solid @grey;
	padding: 25px 30px;
	@media (max-width: @screen-xs-max) {
		padding: 25px 15px;
	}
}

.container-fluid {
	padding: 0 30px;
	@media (max-width: @screen-xs-max) {
		padding: 0 15px;
	}
}

.page-wrapper {
	min-height: 100%;
    height: auto !important;
    @media (min-width: @screen-lg-min) {
    	margin: 0 auto -537px;
    	padding-bottom: 537px;
    }
    @media (min-width: @screen-md-min) {
    	margin: 0 auto -437px;
    	padding-bottom: 437px;
    }
    @media (max-width: @screen-sm-max) {
    	margin: 0 auto -100px;
    	padding-bottom: 100px;
    }
}

.content {
	padding: 0 0 30px;
	margin-left: auto;
	margin-right: auto;
	.container-fluid {
		max-width: 1200px;
	}
    &--main {
    	padding: 0;
		.container-fluid {
			max-width: 1600px;
		}
    }
}

.btn {
	padding: 18px 30px;
	font-size: 16px;
	border-width: 2px;
	border-style: solid;
	border-radius: 0;
	text-decoration: none;
	color: @black;
	background: transparent;
	outline: 0;
	transition: background ease .5s;
	&:active, &:focus, &:active:focus {
		outline: none;
		box-shadow: none;
	}
	@media (max-width: @screen-xs-max) {
    	font-size: 14px;
    	padding: 12px 30px;
    }
}

.btn--yellow {
	border-color: @yellow;
	&:hover {
		background: @yellow;
	}
	&.btn--fill {
		background: @yellow;	
	}
}

.btn--orange {
	border-color: @orange;
	&:hover {
		background: @orange;
	}
	&.btn--fill {
		background: @orange;	
	}
}

.btn--brightorange {
	border-color: @brightorange;
	&:hover {
		background: @brightorange;
	}
	&.btn--fill {
		color: #fff;
		background: @brightorange;	
	}
}

.btn--green {
	border-color: @green;
	&:hover {
		background: @green;
	}
	&.btn--fill {
		background: @green;
	}
}

.btn--lightpink {
	border-color: @lightpink;
	&:hover {
		background: @lightpink;
	}
	&.btn--fill {
		color: #fff;
		background: @lightpink;
	}
}

.btn--dotted {
	border-color: #fff;
	border-style: dashed;
}

.btn--fw {
	@media(max-width: @screen-xs-max) {
		width: 100%;
	}
}

.btn--brush {
	span {
		vertical-align: middle;
	}
}

.line-form {
	background-color: @sand;
	padding: 30px 65px;
	font-size: 0;
	@media(max-width: @screen-xs-max) {
		padding: 30px 15px;
	}
}

.line-form__text {
	.text-nb();
	font-size: 25px;
	width: 50%;
	display: inline-block;
	vertical-align: middle;
	@media(max-width: @screen-md-max) {
		width: 100%;
		display: block;
		margin-bottom: 15px;
	}
}

.line-form__form {
	width: 50%;
	display: inline-block;
	vertical-align: middle;
	@media(max-width: @screen-md-max) {
		width: 100%;
		display: block;
	}
}

.line-form__input,
.line-form__button {
	display: inline-block;
	vertical-align: middle;
	height: 55px;
}

.line-form__input {
	border: 0;
	width: 209px;
	margin-right: 25px;
	font-size: 16px;
	@media(max-width: @screen-xs-max) {
		margin-bottom: 15px;
		width: 100%;
	}
}

.line-form__button {
	padding: 15px 20px;
}

.mini-nav {
	text-align: center;
}

.mini-nav__text,
.mini-nav__icons {
	display: inline-block;
	vertical-align: middle;
	color: @darkgrey;
}

.mini-nav__text {
	.text-nb();
	font-size: 22px;
	margin-right: 25px;
	@media(max-width: @screen-xs-max) {
		margin-right: 0;
		width: 100%;
	}
}

.mini-nav__icons {
	font-size: 40px;
    line-height: 1;
    &:hover {
    	color: @yellow;
    }
    @media(max-width: @screen-xs-max) {
		margin-right: 0;
		width: 100%;
	}
}

.mini-nav__icon {
	margin-right: 10px;
}

.price {
	display: inline-block;
	padding: 0 5px;
	background: @yellow;
	.text-nb;
	color: #000;
	font-size: 20px;
	&:after {
		content: '₽';
	}
}

.nav-panel {
	background: @beige;
    text-align: center;
}

.nav-panel__item__wrapper {
	text-align: center;
    padding: 15px 0;
}

.nav-panel__item {
	display: inline-block;
	color: @darkgrey;
	text-decoration: none;
	font-size: 46px;
	line-height: 46px;
    height: 46px;
	&:not(:last-child) {
		margin-right: 15px;
	}
	&:hover {
		color: @yellow;
	}
}

.page-heading {
	text-align: center;
	margin-bottom: 70px;
}

.para-block-wrapper {
	height: 386px;
	overflow: hidden;
	position: relative;
}

.para-block {
	height: 100%;
	width: 100%;
	background-size: auto 200%;
	background-position-y: 0px;
	background-position-x: 50%;
	background-repeat: no-repeat;
	transform: translateZ(0);
}

.para-block__btn-wrapper {
	position: absolute;
	width: 100%;
	bottom: 40px;
	padding: 0 15px;
	@media(max-width: @screen-xs-max) {
		bottom: 20px;
	}
}

.adv {
	width: 100%;
	height: 695px;
	padding: 55px 0 35px;
	position: relative;
	text-align: center;
	color: #fff;
	background-position: center center;
	background-repeat: no-repeat;
	display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.adv__overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
}

.adv__nav {
	position: relative;
	z-index: 5;
}

.adv__nav__item {
	color: #fff;
	text-decoration: none;
	display: inline-block;
	vertical-align: top;
	font-size: 40px;
	line-height: 1;
	&:not(:last-child) {
		margin-right: 10px;
	}
	&:hover,
	&:active,
	&:focus,
	&:hover:active,
	&:hover:focus,
	&:active:focus {
		color: @yellow;
		text-decoration: none;
	}
	&.active {
		color: @yellow;
	}
}

.adv__content {
	position: relative;
	padding: 50px 70px;
	width: 80%;
	max-width: 720px;
	text-align: center;
	z-index: 5;
	margin: auto;
	a {
		color: @yellow;
	}
	@media (max-width: @screen-xs-max) {
		max-width: 100%;
		padding: 50px 10px;
	}
}

.adv__content__icon {
	color: @yellow;
	margin: 0 auto 35px;
	font-family: 'oknavips-regular';
	font-size: 56px;
	line-height: 1;
	&--balkon {
		&:before {
			content: '\1017';
		}
	}
	&--teplopaket {
		&:before {
			content: '\1009';
		}
	}
	&--steklopaket {
		&:before {
			content: '\1008';
		}
	}
}

.adv__content__big-text {
	font-size: 32px;
	line-height: 1.4;
	color: #fff;
	margin-bottom: 25px;
}

.adv__content__small-text {
	.text-16;
	color: #fff;
	margin-bottom: 25px;
}

.adv__price-wrapper {
	display: inline-block;
	font-size: 28px;
	margin-bottom: 25px;
}

.adv__eye {
	z-index: 5;
	position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
	&--active {
		
	}
}

.icon-eye {
	fill: @yellow;
	width: 30px;
	height: 25px;
}

.useful {
	height: 250px;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	@media (max-width: @screen-xs-max) {
		margin-bottom: 15px;
	}
}

.useful__overlay {
	height: 100%;
	background-color: rgba(0, 0, 0, .45);
	display: flex;
	padding: 15px;
}

.useful__content {
	margin: auto;
	text-align: center;
}

.useful__title {
	.text-nb();
	font-size: 27px;
	color: @yellow;
	margin-bottom: 10px;
}

.useful__text {
	color: #fff;
}

.next-block {
	height: 250px;
	background-color: @yellow;
	padding: 25px;
	display: flex;
}

.next-block__content {
	margin: auto;
	text-align: center;
}

.next-block__word {
	font-size: 18px;
}

.next-block__title {
	.text-nb();
	font-size: 33px;
    line-height: 1;
}

.info {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 25px;
	width: 100%;
	&--v {
		@media (max-width: @screen-xs-max) {
			margin-bottom: 0;
		}
		.info__item {
			@media (max-width: @screen-xs-max) {
				flex-direction: column;
			}
		}
		.info__text {
			@media (max-width: @screen-xs-max) {
				width: 100%;
			}
		}
		.info__icon {
			@media (max-width: @screen-xs-max) {
				margin-bottom: 15px;
			}
		}
	}
}

.info__item {
	width: 100%;
	display: flex;
    align-items: center;
    margin-right: 35px;
    margin-bottom: 10px;
    &--top {
    	align-items: flex-start;
    }
}

.info__icon {
	margin-right: 25px;
	flex-shrink: 0;
	.icon {
		fill: @darkgrey;
		vertical-align: middle;
	}
	&--margin50 {
		margin-right: 50px;
	}
}

.icon-wallet {
	width: 30px;
	height: 26px;
}

.icon-buy_cart {
	width: 35px;
	height: 30px;
}

.icon-bricks {
	width: 38px;
	height: 26px;
}

.icon-plus_minus {
	width: 30px;
	height: 27px;
}

.icon-window {
	width: 19px;
	height: 32px;
}

.icon-warm_sun {
	width: 37px;
	height: 37px;
}

.icon-expand {
	width: 38px;
	height: 28px;
}

.icon-pig {
	width: 44px;
	height: 31px;
}

.icon-coins {
	width: 50px;
	height: 30px;
}

.icon-profile_temp {
	width: 30px;
	height: 30px;
}

.icon-profile_volume {
	width: 30px;
	height: 30px;
}

.icon-profile_sun {
	width: 30px;
	height: 30px;
}

.icon- {
	&otdelka_1steni,
	&otdelka_2pol,
	&otdelka_3sideing,
	&otdelka_4_utepl,
	&otdelka_5chernovoy {
		width: 128px;
		height: 128px;
	}
}

.icon-plus {
	width: 33px;
	height: 33px;
}

.icon-metro {
	width: 30px;
	height: 30px;
}

.metro-green {
	fill: #006633;
}

.metro-blue {
	fill: #003399;
}

.metro-orange {
	fill: #ff9900;
}

.metro-yellow {
	fill: #ffcc00;
}

.icon-phone {
	fill: #fff;
	width: 19px;
	height: 19px;
	vertical-align: middle;
}

.icon-mail {
	width: 37px;
	height: 26px;
}

.icon-phone_ring {
	width: 26px;
	height: 27px;
}

.info__text {
	&--grey {
		.text-16;
		.text-nb;
		color: @darkgrey;
		a {
			color: @darkgrey;
			text-decoration: none;
			&:hover, &:active, &:focus {
				color: @darkgrey;
			}
		}
	}
	&--green {
		.text-nb;
		font-size: 22px;
		line-height: 1.09;
		color: @green;
		a {
			display: block;
			.text-14;
			.text-s;
		    margin-top: 5px;
		}
	}
}

.gradient-right {
	height: 100%;
	width: 70px;
	position: absolute;
	top: 0;
	right: -1px;
	background: -webkit-linear-gradient(to left, #fff, transparent);
	background: -moz-linear-gradient(to left, #fff, transparent);
	background: -ms-linear-gradient(to left, #fff, transparent);
	background: -o-linear-gradient(to left, #fff, transparent);
	background: linear-gradient(to left, #fff, transparent);
}

.row--flex {
	display: flex;
}

.row--content-center {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.block--big-margin {
	@media (max-width: @screen-xs-max) {
		margin-bottom: 30px;
	}
	@media (min-width: @screen-sm-min) {
		margin-bottom: 85px;
	}
}

.block__item {
	margin-bottom: 25px;
}

.block__item--big-margin {
	margin-bottom: 40px;
}

.img-left {
	float: left;
	@media (max-width: @screen-xs-max) {
		float: none;
		margin: 0 auto;
	}
}

.img-right {
	float: right;
	@media (max-width: @screen-xs-max) {
		float: none;
		margin: 0 auto;
	}
}

.img-right--sm-center {
	@media (max-width: @screen-sm-max) {
		float: none;
		margin: 0 auto;
	}
}

.section__header {
	.text-s;
	font-size: 24px;
	line-height: 1;
	margin-bottom: 40px;
}

.news-preview {
	margin-bottom: 40px;
	position: relative;
	display: block;
	&--main {
		margin-bottom: 75px;
		.text-16;
		@media (max-width: @screen-sm-max) {
			.text-14;
		}
		@media (max-width: @screen-xs-max) {
			margin-bottom: 40px;
		}
		.news-preview__date {
			margin-bottom: 30px;
		}
	}
	.sticker {
		top: -5px;
	    left: 80px;
	}
}

.news-preview__date {
	color: @darkgrey;
	margin-bottom: 15px;
	display: inline-block;
}

.news-preview__text {
	display: block;
}

.news-preview__img  {
	background: url('http://placekitten.com/276/187') center center no-repeat;
	background-size: cover;
	height: 155px;
	margin-bottom: 20px;
	overflow: hidden;
}

.sticker {
	display: inline-block;
	padding: 5px 10px;
	position: absolute;
	border-radius: 11px;
	&--important {
		background: @red;
		color: #fff;
	}
	&--discount {
		background: @brightgreen;
		color: #fff;
	}
}

.img-pod {
	margin: 0 auto 30px;
}

.info-v {
	width: 85%;
	margin: 0 auto;
	h3 {
		text-align: center;
	}
	p {
		text-align: center;
		@media (max-width: @screen-xs-max) {
			text-align: left;
		}
	}
}

.review__wrapper-out {
	display: none;
}

.review__wrapper {
	column-width: 210px;
	column-gap: 30px;
	position: relative;
}

.review__overlay {
	position: absolute;
	height: 75px;
	width: 100%;
	bottom: 0;
	background: -ms-linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
	background: -moz-linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
	background: -o-linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
	background: -webkit-linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
	background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0) 100%);
}

.review {
	border: 1px solid #e1e1e1;
	padding: 30px;
	margin-bottom: 30px;
	display: inline-block;
	break-inside: avoid-column;
	font-size: 14px;
}

.review__header {
	color: @green;
	margin-bottom: 15px;
}

.review p {
	font-size: 14px;
	margin-bottom: 20px;
}

.review__sign {
	color: #969696;
	.text-i();
}

.modal-content {
	border-radius: 0;
}

.modal-header {
	border-bottom: 0;
	padding: 32px 25px 15px;
}

.close {
	outline: 0;
	span {
		font-size: 40px;
		font-weight: 100;
	    line-height: 0.5;
	}
}

.modal-header-img {
    width: 88px;
    height: 106px;
    background-image: url('../img/main/buttons_sprite.png');
    margin: 37px auto 20px;
    &--measure {
    	background-position: 0 0;
    }
    &--question {
    	background-position: -176px 0;
    }
    &--callback {
    	background-position: -265px 0;
    }
}

.modal-title {
	text-align: center;
	font-size: 22px;
	.text-nb();
}

.modal-body {
	padding: 35px 95px 85px;
	@media (max-width: @screen-xs-max) {
		padding: 35px 30px 85px;
	}
}

.modal-content {
	input, textarea {
		width: 100%;
		margin-bottom: 20px;
	}
	textarea {
		height: 157px;
	}
}

/*------------DIVIDE PAGE START--------------*/

.tile__container {
	overflow: hidden;
}

.tile {
	display: block;
	float: left;
	position: relative;
	@media (max-width: @screen-xs-max) {
		padding-bottom: 85%;
	}
	@media (min-width: @screen-sm-min) {
		padding-bottom: 50%;
	}
	@media (min-width: @screen-md-min) {
		padding-bottom: 33.33333333%;
	}
}

.tile__inner {
	position: absolute;
	left: 10px;
	right: 10px;
	top: 10px;
	bottom: 10px;
	overflow: hidden;
	text-decoration: none;
	text-align: center;
	color: @black;
	background: @beige;
	&:hover,
	&:active,
	&:focus,
	&:hover:active,
	&:active:focus {
		text-decoration: none;
		color: @black;
	}
	&--bordered {
		background: transparent;
		padding: 8% 30px 35px;
		border: 4px solid @yellow;
		.text-nb;
		font-size: 49px;
		line-height: 1;
		color: @yellow-grey;
		&:hover,
		&:active,
		&:focus,
		&:hover:active,
		&:active:focus {
			color: @yellow-grey;
		}
	}
	&.disable {
		filter: grayscale(100%);
		pointer-events: none;
		cursor: default;
	}
	h3 {
		margin-bottom: 15px;
	}
}

.tile__img {
	width: 100%;
}

.tile__text {
	position: absolute;
	left: 35px;
	right: 35px;
	bottom: 0;
	text-align: left;
}

/*------------DIVIDE PAGE END--------------*/


/*-------------FILTER START--------------*/

.tab__content {
	display: none;
	&:first-child {
		display: block;
	}
}

.filter__link-wrapper {
	margin: 40px 0;
}

.filter__link {
	.text-16;
	color: @darkgrey;
	text-decoration: none;
	&:not(:last-child) {
		&:after {
			content: ' / ';
			color: @darkgrey;
		}
	}
	&.active {
		span {
			color: @darkgrey;
			text-decoration: none;
			.text-i;
			&:hover, &:focus {
				cursor: default;
			}
		}
	}
	span {
		color: @linkblue;
		text-decoration: underline;
		white-space: nowrap;
		&:hover, &:focus {
			text-decoration: none;
			cursor: pointer;
		}
	}
}

.filter__btn-wrapper {
	text-align: justify;
	padding: 2px;
	&:after {
		content: ' i i i i i i';
        word-spacing: 65px;
        padding-left: 62px;
        visibility: hidden;
	}
	@media (max-width: @screen-xs-max) {
		margin-bottom: 0;
	}
	@media (min-width: @screen-sm-min) {
		margin-bottom: -20px;
	}
}

.filter__btn {
	width: 68px;
	height: 47px;
	margin: 0 0 25px 0;
	padding: 3px;
	display: inline-block;
	a {
		display: block;
		width: 100%;
		height: 100%;
	}
	&--default {
		a {
			background: url('../img/icons/btn-default.png') center center no-repeat;
		}
	}
	&.active {
		outline: 2px solid @green;
		cursor: default;
	}
}

.filter__sign {
	.text-16;
	.text-i;
	color: @darkgrey;
}

.filter__sign--normal {
	.text-s;
}

/*-------------FILTER END--------------*/

/*-------------SELECT START--------------*/

.select2-container--default .select2-selection--single {
	border-radius: 0;
	border: solid 1px #d2d2d2;
	height: 44px;
	&:focus {
		outline: 0;
	}
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 42px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
	// top: 10px;
}

.select2-dropdown {
	border-radius: 0;
}

.select2-container--default .select2-selection--single {
	border: 1px solid #d2d2d2;
	border-radius: 0;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 44px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 44px;
}

/*-------------SELECT END--------------*/

/*-------------COME BANNER START--------------*/

.come {
	margin: 30px 0 40px;
	padding: 25px 0 20px;
	background-color: @beige;
	h3 {
		margin-bottom: 0;
	}
}

.come__inner {
	display: flex;
	justify-content: flex-start;
	flex-flow: row wrap;
	@media (min-width: @screen-lg-min) {
		justify-content: center;
	}
}

.come__icon,
.come__content,
.come__form {
	padding: 10px 0;
}

.come__icon {
	padding-right: 20px;
}

.come__content {
	padding-right: 50px;
}

.come__form {
	input {
		border: 0;
		margin-right: 26px;
	}
	input,
	.btn {
		vertical-align: top;
		@media (max-width: @screen-xs-max) {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	.btn {
		margin-bottom: 0;
	    padding: 12px 30px;
	}
	@media (max-width: @screen-xs-max) {
		width: 100%;
	}
}

/*-------------COME BANNER END--------------*/

/*-------------SLIDER LAST WORKS START--------------*/

.slide-container--last-works {
	position: relative;
	.slider__item {
		margin-right: 30px;
		height: 205px;
		width: 265px;
		background: @lightgrey;
		position: relative;
		&:active, &:focus, &:active:focus {
			outline: none;
		}
	}
	.slick-arrow {
		border: 10px solid transparent;
		z-index: 10;
		&:before, &:after {
			content: '';
		}
	}
	.slick-prev {
		border-right: 6px solid @black;
		left: 25px;
	}
	.slick-next {
		border-left: 6px solid @black;
		right: 25px;
	}
}

.slider--last-works__overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(246, 207, 69, .7);
}

.slider--last-works__content {
	position: absolute;
	padding: 30px;
	bottom: 0;
}

.slider--last-works__content-xs {
	margin-bottom: 20px;
	display: none;
}

.slider--last-works__price {
	font-size: 20px;
	font-weight: bold;
	margin-bottom: 15px;
}

/*-------------SLIDER LAST WORKS END--------------*/