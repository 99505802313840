.header {
	height: 70px;
	background: @green;
    width: 100%;
    padding: 0 25px;
    margin-top: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: height 0.5s ease;
    align-items: flex-end;
    position: relative;
    z-index: 10;
    @media (max-width: @screen-xs-max) {
    	padding: 0 15px;
    }
}

.header-open {
	transition: height 0.5s ease;
	@media (max-width: @screen-sm-max) {
		height: 140px;
	}
	@media (max-width: @screen-xs-max) {
		height: 380px;
	}
}

.header__logo-wrapper {
	height: 70px;
	margin-right: 30px;
	display: flex;
	align-items: center;
}

.icon-logo_okna {
	fill: #fff;
	height: 34px;
	@media (min-width: @screen-lg-min) {
		width: 166px;
	}
	@media (max-width: @screen-md-max) {
		width: 133px;
	}
	@media (max-width: @screen-sm-max) {
		width: 133px;
	}
	@media (max-width: @screen-xs-max) {
		width: 140px;
	}
}

.header__nav {
	height: 70px;
	@media (max-width: @screen-sm-max) {
		position: absolute;
		top: -70px;
		left: 0;
	    width: 100%;
	    background: #3f4345;
	    padding: 0 25px;
	}
	@media (max-width: @screen-xs-max) {
		top: -310px;
	    height: 310px;
	    padding: 30px 25px 30px 30px;
	}
}

.header__nav-open {
	top: 0;
	transition: all 0.5s ease;
}

.menu {
	padding: 0;
	margin: 0;
	height: 100%;
	list-style: none;
}

.menu__item {
	height: 100%;
	margin-right: 37px;
	display: inline-block;
	vertical-align: top;
	&:last-child {
		margin-right: 0;
	}
	&:hover {
		.menu__link {
			color: @yellow;
			text-decoration: none;
			@media (min-width: @screen-md-min) {
				border-bottom: 5px solid @yellow;
			}
	    }
	}
	@media (max-width: @screen-md-max) {
		margin-right: 20px;
	}
	@media (max-width: @screen-xs-max) {
		height: 25px;
		display: block;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menu__link {
	height: 100%;
	display: block;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
    line-height: 70px;
    padding: 0;
    background-color: transparent;
    &:hover, &:focus, &:active {
    	color: #fff;
    	text-decoration: none;
    }
	@media (max-width: @screen-xs-max) {
		line-height: 20px;
	}
}

.submenu {
    display: none;
    background: #f4f4f4;
    padding: 25px;
    list-style: none;
    position: absolute;
    z-index: 10;
    &.fallback {
    	display: none;
    }
    @media (max-width: @screen-sm-max) {
    	display: none !important;
    }
}

.submenu__item {
	height: 25px;
	cursor: pointer;
	font-size: 16px;
	&:focus {
		outline: 0;
	}
}

.submenu__link {
	color: #838383;
	text-decoration: none;
	&:hover,
	&:active,
	&:focus,
	&:active:focus {
		color: #000;
	}
}

.header__r-wrapper {
	height: 70px;
	flex-grow: 2;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header__telephone {
	.text-b;
	font-size: 18px;
	color: #fff;
	display: inline-block;
	vertical-align: middle;
}

.header__telephone--xs {
	background: #fff;
	display: none;
	width: 20px;
	height: 20px;
}

.header__man {
	height: 25px;
	width: 22px;
	display: inline-block;
	vertical-align: middle;
	background: url('../img/icons/lk_icon.svg') 50% bottom no-repeat;
	background-size: contain;
	position: relative;
	&:hover, &:focus, &:active {
		background: url('../img/icons/y_lk_icon.svg') 50% bottom no-repeat;
		background-size: contain;
	}
}

.number-circle {
	min-height: 15px;
	min-width: 15px;
	padding: 2px;
	border-radius: 50%;
	background: @pink;
	font-size: 12px;
	line-height: 1;
	position: absolute;
	top: 0;
	right: 0;
	color: #fff;
	text-align: center;
}

.header__search {
    height: 30px;
	width: 20px;
	display: inline-block;
	vertical-align: middle;
	margin-left: 25px;
	background-color: transparent;
	cursor: pointer;
	position: relative;
	@media (max-width: @screen-md-max) {
		display: none;
	}
}

.icon-search {
	fill: #fff;
	height: 30px;
    width: 20px;
    &:hover {
    	fill: @yellow;
    }
}

.header__search-icon {
	position: absolute;
	top: 0;
	right: 0;
}

.header__search-icon-submit {
	fill: @icon-grey;
	&:hover {
    	fill: @icon-grey;
    }
}

.header__search-open {
	height: 30px;
	background: transparent;
	&:hover {
		background: transparent;
	}
	.header__search-icon {
		display: none;
	}
}

.searchbox {
	height: 30px;
    width: 0;
    padding: 0 10px;
	float: right;
	position: relative;
	outline: 0;
	cursor: default;
	border-radius: 15px;
	background: #fff;
	display: none;
}

.searchbox__input {
	height: 28px;
	width: 150px;
	padding: 0;
	margin-top: 1px;
	float: left;
	outline: 0;
	border: 0;
}

.searchbox__submit {
	display: inline-block;
	height: 30px;
	width: 18px;
	padding: 0;
	border: 0;
	outline: 0;
	background-color: transparent;
}

.burger {
	width: 21px;
	height: 19px;
    vertical-align: middle;
    display: inline-block;
    position: relative;
    span {
    	display: block;
    	width: 100%;
    	height: 3px;
    	background: #fff;
    	&:not(:last-child) {
    		margin-bottom: 5px;
    	}
    }
    &:hover, &:active, &:focus {
    	span {
	    	background: @yellow;
		}
    }
}

.header__burger {
    margin-top: 7px;
    margin-left: 30px;
    @media (min-width: @screen-md-min) {
    	display: none !important;
    }
    @media (max-width: @screen-sm-max) {
    	display: inline-block !important;
    	margin-top: 0;
    }
}

.burger {
  width: 21px;
  height: 19px;
  vertical-align: middle;
  display: inline-block;
  position: relative;
}

.burger span {
  display: block;
  width: 100%;
  height: 3px;
  background: #fff;
}

.burger span:not(:last-child) {
  margin-bottom: 5px;
}

.burger:hover span,
.burger:active span,
.burger:focus span {
  background: #f6cf45;
}

.header__burger {
  margin-top: 7px;
  margin-left: 30px;
}

@media (min-width: @screen-md-min) {
  .header__burger {
    display: none !important;
  }
}

@media (max-width: @screen-sm-max) {
  .header__burger {
    display: inline-block !important;
    margin-top: 0;
  }
}